export default {
    ellipsis:{
        lineHeight: 0
    },
    icon: {
        display: 'none'
    },
    root: {
        padding: 0,
        margin: 6,
        height: 22,
        fontSize: 10,
        minWidth: 22,
        lineHeight: 22,

    },
    textPrimary: {
        // '&.Mui-selected': {
        //     height: 24,
        //     minWidth: 24
        // }
    }

};

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  dialog: {
    border: '2px solid #707070',
    borderRadius: '3px'
  },
  border: {
    border: '2px solid #707070',
    borderRadius: '3px'
  },
  root: {
    minWidth: '350px',
    margin: 'auto',
    padding: '20px'
  },
  text: {
    fontSize: '15px !important',
    letterSpacing: ' 0px',
    color: '#000000',
    textAlign: 'center',
    margin: '0px 50px 10px',
    fontWeight: 500
  },
  subText: {
    fontSize: '14px !important',
    letterSpacing: ' 0px',
    color: '#F80303',
    textAlign: 'center',
    margin: '0px 50px 10px'
  },
  text1: {
    fontSize: '15px !important',
    letterSpacing: ' 0px',
    color: '#F80303',
    textAlign: 'center',
    margin: '0px 50px 10px'
  },
  heading: {
    fontSize: '20px !important',
    letterSpacing: ' 0px',
    color: '#F80303',
    textAlign: 'center',
    margin: '0px 50px 10px'
  },
  gridBtn: {
    marginTop: '40px',
    '& .MuiGrid-item': {
      textAlign: 'center'
    }
  },
  modifyBtn: {
    margin: '0px 10px',
    minWidth: 100
  },
  redBtn: {
    borderRadius: '2px',
    background: '#F15E5E 0% 0% no-repeat padding-box',
    '&:hover ': {
      borderRadius: '2px',
      background: '#F15E5E 0% 0% no-repeat padding-box'
    }
  },
  greyBtn: {
    background: ' #8B8B8B 0% 0% no-repeat padding-box',
    color: 'white',
    borderRadius: '2px',
    '&:hover ': {
      background: ' #8B8B8B 0% 0% no-repeat padding-box',
      borderRadius: '2px'
    }
  },
  blueBtn: {
    background: '#0B74D1 0% 0% no-repeat padding-box',
    color: 'white',
    borderRadius: '2px',
    '&:hover ': {
      background: '#0B74D1 0% 0% no-repeat padding-box',
      color: 'white',
      borderRadius: '2px'
    }
  },
  buttonProgress: {
    color: 'white',
    // position: 'absolute',
    top: '50%',
    left: '50%'
    // marginTop: -12,
    // marginLeft: -12,
  }
}));

export default function DialogDefault({
  status = false,
  handleClose,
  handleDialogClickSubmit,
  dailog,
  hasBorder = false
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(status);
  const [loaddialog, setLoadedialog] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [data, setData] = React.useState(dailog);
  console.log(data,"data----");

  const handleDialogSubmit = () => {
    handleDialogClickSubmit();
    setLoadedialog(true);
  };

  console.log('loadingdialog', loaddialog);
  switch (data) {
    case 'setAdd':
      setData(addData);
      break;
    case 'setEdit':
      setData(editData);
      break;
    case 'setDelete':
      setData(deleteData);
      break;
    case 'SetEditUserData':
      setData(editUserData);
      break;
    case 'SetEditUserDataStandard':
      setData(editUserDataStandard);
      break;
    case 'SetAddUserData':
      setData(addUserData);
      break;
    case 'setEditLicenseData':
      setData(editLicenseData);
      break;
    case 'setEditTransfersData':
      setData(editTransfersData);
      break;
    case 'setEditTransfersData1':
      setData(editTransfersData1);
      break;
    case 'setAddLicenseData':
      setData(addLicenseData);
      break;
    case 'setAddAdministLicenseData':
      setData(addAdministLicenseData);
      break;
    case 'setDeleteLicenseData':
      setData(deleteLicenseData);
      break;
    case 'setDeleteMedicalLicenseData':
      setData(deleteMedicalLicenseData);
      break;
    case 'setPersonalDocs':
      setData(PersonalDocs);
      break;
    case 'setConfirmationFormData':
      setData(confirmationFormData);
      break;
    case 'setConfirmFormData':
      setData(confirmFormData);
      break;
    case 'setDocSubmit':
      setData(docSubmit);
      break;
    case 'setDeleteTransfersData':
      setData(deleteTransfersData);
      break;
    case 'setCancelTransfersData':
      setData(cancelTransfersData);
      break;
    case 'setUploadHandleData':
      setData(uploadHandleData);
      break;
    case 'setUploadUserData':
      setData(useruploadData);
      break;
    case 'setReceiptsUserData':
      setData(userreceiptsData);
      break;

    // Auditer Lisence
    case 'setAprobarLicencia':
      setData(aprobarLicencia);
      break;
    case 'setLicenseenAuditor':
      setData(licenseenAuditor);
      break;
    case 'setNoAprobarLicencia':
      setData(noAprobarLicencia);
      break;
    case 'setApplyLicencia':
      setData(applyLicencia);
      break;
    case 'setcommunication':
      setData(communication);
      break;
    case 'setaprobando':
      setData(aprobando);
      break;
    case 'setnoaprobando':
      setData(noaprobando);
      break;
    case 'SetdeleteSolapasData':
      setData(deleteSolapasData);
      break;
    case 'Setlogout':
      setData(logout);
      break;
    case 'resendData1':
      setData(resendData1);
      break;
    case 'SetUpdateUserData':
      setData(actualizar);
      break;
    case 'setQuitRelationship':
      setData(quitRelationship);
      break;
    case 'setnohaveaprobando':
      setData(nohaveaprobando);
      break;
    default:
      break;
  }

  return (
    <div>
      {data && (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          className={classes.dialog}>
          <Box
            className={`${classes.root} ${hasBorder ? classes.border : null}`}>
            <div
              className={classes.imgDiv}
              style={{ textAlign: 'center', paddingBottom: '15px' }}>
                {data.satus ? (

                  <img src={data.img} alt="confirm" style={{width:'170px'}}/>
                ) : (

              <img src={data.img} alt="confirm" />
                ) }
            </div>
            {/* <Typography className={classes.text}>{data.text}</Typography> */}
            {data.heading && (
              <Typography className={classes.heading}>
                {data.heading}
              </Typography>
            )}
            {data.text && (
              <Typography className={classes.text}>{data.text}</Typography>
            )}
            {data.text1 && (
              <Typography className={classes.text}>{data.text1}</Typography>
            )}
            {data.text2 && (
              <Typography className={classes.text1}>{data.text2}</Typography>
            )}
            {data.text3 && (
              <Typography className={classes.text1}>{data.text3}</Typography>
            )}
            {data.text4 && (
              <Typography className={classes.text}>{data.text4}</Typography>
            )}
            {data.subText && (
              <Typography className={classes.subText}>
                {data.subText}
              </Typography>
            )}
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.gridBtn}>
              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={handleClose}
                  className={`${classes.SaveBtn} ${classes.modifyBtn}  ${classes.greyBtn}`}>
                  {data.cancleBtn}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  color="primary"
                  variant="contained"
                  size="medium"
                  onClick={() => handleDialogSubmit()}
                  className={`${classes.SaveBtn} ${classes.modifyBtn} ${
                    data.subText ? classes.redBtn : classes.blueBtn
                  }`}
                  endIcon={
                    loaddialog && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )
                  }
                  //  endIcon={ <CircularProgress size={24} className={classes.buttonProgress} />}
                  disabled={loaddialog}
                  //  disabled={loadingdialog}
                >
                  {data.sucessBtn}
                </Button>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </Box>
        </Dialog>
      )}
    </div>
  );
}

const deleteData = {
  img: '/images/close-circle-outline.svg',
  text: '¿Está seguro de eliminar este usuario?',
  subText: 'Esta operación no puede deshacerse',
  sucessBtn: 'Eliminar',
  cancleBtn: 'Cancelar'
};
const deleteLicenseData = {
  img: '/images/close-circle-outline.svg',
  text: '¿Está seguro de eliminar esta regla?',
  subText: 'Esta operación no puede deshacerse',
  sucessBtn: 'Eliminar',
  cancleBtn: 'Cancelar'
};
const deleteMedicalLicenseData = {
  img: '/images/close-circle-outline.svg',
  text: '¿Está seguro de eliminar esta regla?',
  subText: 'Esta operación no puede deshacerse',
  sucessBtn: 'Eliminar',
  cancleBtn: 'Cancelar'
};

const PersonalDocs = {
  img: '/images/Icon awesome-thumbs-up.svg',
  text: '¡ATENCIÓN!',
  subText: '¿Está seguro de guardar los cambios?',
  sucessBtn: 'Confirmar',
  cancleBtn: 'Cancelar'
};

const confirmationFormData = {
  img: '/images/Icon awesome-thumbs-up.svg',
  text: '¡ATENCIÓN!',
  subText: '¿Está seguro de guardar los cambios?',
  sucessBtn: 'Confirmar',
  cancleBtn: 'Cancelar'
};

const confirmFormData = {
  img: '/images/Icon awesome-thumbs-up.svg',
  text: '¡ATENCIÓN!',
  subText: '¿Está seguro de guardar los cambios?',
  sucessBtn: 'Confirmar',
  cancleBtn: 'Cancelar'
};

const docSubmit = {
  img: '/images/Icon awesome-thumbs-up.svg',
  text: '¡ATENCIÓN!',
  subText: '¿Está seguro de guardar los cambios?',
  sucessBtn: 'Confirmar',
  cancleBtn: 'Cancelar'
};

const deleteTransfersData = {
  img: '/images/close-circle-outline.svg',
  text: '¿Está seguro de eliminar este POI?',
  subText: 'Esta operación no puede deshacerse',
  sucessBtn: 'Eliminar',
  cancleBtn: 'Cancelar'
};

const cancelTransfersData = {
  img: '/images/close-circle-outline.svg',
  text: '¿Está seguro de cancelar el envío?',
  subText: 'Esta operación no puede deshacerse',
  sucessBtn: 'Cancelar',
  cancleBtn: 'Volver'
};
const editData = {
  img: '/images/Icon awesome-exclamation-circle.svg',
  text: '¿Está seguro de modificar este cliente?',
  sucessBtn: 'Modificar',
  cancleBtn: 'Cancelar'
};
const addData = {
  img: '/images/Icon awesome-thumbs-up.svg',
  text: 'Ud. está creando un nuevo cliente',
  sucessBtn: 'Aceptar',
  cancleBtn: 'Cancelar'
};

// const editUserData = {
//   img: '/images/Icon awesome-exclamation-circle.svg',
//   text: '¿Está seguro de modificar este usuario?',
//   sucessBtn: 'Modificar',
//   cancleBtn: 'Cancelar'
// };

const editUserData = {
  img: '/images/Icon awesome-exclamation-circle.svg',
  heading: '¡ATENCIÓN!',
  // text: 'Ud. está otorgando permisos médicos',
  // text1: 'a un perfil administrativo en su sistema numi.',
  // text2: 'Esto anula los certificados de HIPAA Compliance',
  // text3: 'y otros certificados y normas de seguridad.',
  text4: '¿Está seguro de realizar este cambio?',
  sucessBtn: 'Confirmar',
  cancleBtn: 'Cancelar'
};

const editUserDataStandard = {
  img: '/images/Icon awesome-thumbs-up.svg',
  text: '¡ATENCIÓN!',
  subText: '¿Está seguro de guardar los cambios?',
  sucessBtn: 'Confirmar',
  cancleBtn: 'Cancelar'
};

const addUserData = {
  img: '/images/Icon awesome-thumbs-up.svg',
  text: 'Ud. está creando un nuevo usuario',
  sucessBtn: 'Aceptar',
  cancleBtn: 'Cancelar'
};

const editTransfersData = {
  img: '/images/Icon awesome-exclamation-circle.svg',
  text: '¿Está seguro de modificar esta regla?',
  sucessBtn: 'Aceptar',
  cancleBtn: 'Cancelar'
};
const editTransfersData1 = {
  img: '/images/Icon awesome-exclamation-circle.svg',
  text: '¿Está seguro de modificar esta regla?',
  sucessBtn: 'Modificar',
  cancleBtn: 'Cancelar'
};

const resendData1 = {
  img: '/images/Icon awesome-exclamation-circle.svg',
  text:
    'Está a punto de reenviar el documento original de este formulario, esto pisara la última versión editada, ¿desea avanzar?',
  sucessBtn: 'Aceptar',
  cancleBtn: 'Cancelar'
};

const editLicenseData = {
  img: '/images/Icon awesome-exclamation-circle.svg',
  text: '¿Está seguro de modificar esta licencia?',
  sucessBtn: 'Aceptar',
  cancleBtn: 'Cancelar'
};

const addLicenseData = {
  img: '/images/Icon awesome-exclamation-circle.svg',
  text: '¿Está seguro de crear esta regla?',
  sucessBtn: 'Aceptar',
  cancleBtn: 'Cancelar'
};

const addAdministLicenseData = {
  img: '/images/Icon awesome-exclamation-circle.svg',
  text: '¿Está seguro de crear esta licencia?',
  sucessBtn: 'Aceptar',
  cancleBtn: 'Cancelar'
};

const uploadHandleData = {
  img: '/images/Icon awesome-exclamation-circle.svg',
  heading: '¡ATENCIÓN!',
  text: 'Ud. está por reemplazar',
  text1: 'el CIE 10 en su sistema NUMI.',
  text2: 'Esto eliminará todas las reglas de licencias ',
  text3: 'médicas creadas con anterioridad al proceso.',
  text4: '¿Está seguro de realizar este cambio?',
  sucessBtn: 'Aceptar',
  cancleBtn: 'Cancelar'
};

const useruploadData = {
  img: '/images/Icon awesome-exclamation-circle.svg',
  // heading: '¡ATENCIÓN!',
  text: '¿Está seguro de crear estos usuarios?',
  // text1: 'a un pefil administrativo en su sistema NUMI.',
  // text2: 'Esto anula los certificados de HIPAA Compliance',
  // text3: 'y otros certificados y normas de seguridad.',
  // text4: '¿Está seguro de realizar este cambio?',
  sucessBtn: 'Confirmar',
  cancleBtn: 'Cancelar'
};
const deleteSolapasData = {
  img: '/images/Icon awesome-exclamation-circle.svg',
  heading: '¡ATENCIÓN!',
  text: 'Ud. está borrando una plantilla',
  text2: ' Toda la información que este dentro ',
  text3: 'de la misma será borrada de forma permanente.',
  // text2: "y otros certificados y normas de seguridad.",
  text4: '¿Está seguro de realizar este cambio?',
  sucessBtn: 'Aceptar',
  cancleBtn: 'Cancelar'
};

const userreceiptsData = {
  img: '/images/Icon awesome-exclamation-circle.svg',
  heading: '¡ATENCIÓN!',
  text: 'Ud. está enviando recibos de sueldo',
  text1: '¿Está seguro de realizar este envío?',
  sucessBtn: 'Enviar',
  cancleBtn: 'Cancelar'
};

// Auditor Portal popup

const aprobarLicencia = {
  img: '/images/LicenciaAprobada.svg',
  text: '¿Está APROBANDO la licencia?',
  sucessBtn: 'Aceptar',
  cancleBtn: 'Cancelar'
};
const licenseenAuditor = {
  img: '/images/License en Auditor.svg',
  text: '¿Está AUDITANDO la licencia?',
  sucessBtn: 'Aceptar',
  cancleBtn: 'Cancelar'
};
const noAprobarLicencia = {
  img: '/images/Licencia No Aprobar.svg',
  text: '¿Está NO APROBANDO la licencia?',
  sucessBtn: 'Aceptar',
  cancleBtn: 'Cancelar'
};
const applyLicencia = {
  img: '/images/Icon awesome-exclamation-circle.svg',
  text: '¿Está seguro de solicitar esta licencia?',
  sucessBtn: 'Solicitar',
  cancleBtn: 'Cancelar'
};
const communication = {
  img: '/images/Icon awesome-exclamation-circle.svg',
  text: '¿Está seguro de enviar esta comunicación?',
  sucessBtn: 'Enviar ',
  cancleBtn: 'Cancelar'
};
const aprobando = {
  img: '/images/LicenciaAprobada.svg',
  text: '¿Está APROBANDO la licencia?',
  sucessBtn: 'Aceptar ',
  cancleBtn: 'Cancelar'
};
const noaprobando = {
  img: '/images/Cancelar solicitud.png',
  text: ' Estas por cancelar una solicitud de licencia',
  sucessBtn: 'Confirmar',
  cancleBtn: 'Cancelar',
  satus: "xyz"
};
const nohaveaprobando = {
  img: '/images/NO Aprobar Licencia.svg',
  text: '¿Está NO Aprobar la licencia?',
  sucessBtn: 'Aceptar',
  cancleBtn: 'Cancelar',
};

const actualizar = {
  img: '/images/Icon awesome-thumbs-up.svg',
  text: '¿Está seguro de que desea enviar su información personal?',
  sucessBtn: 'Aceptar',
  cancleBtn: 'Cancelar'
};

const logout = {
  img: '/images/Icon awesome-exclamation-circle.svg',
  text: 'Confirma cerrar salir y cerrar sesión?',
  sucessBtn: 'Confirmar',
  cancleBtn: 'cancelar'
};

const quitRelationship = {
  img: '/images/close-circle-outline.svg',
  text: 'Al dejar de compartir tu perfil dejaras de tener acceso a la compañía con la que venias colaborando.',
  sucessBtn: 'Confirmar',
  cancleBtn: 'cancelar'
};

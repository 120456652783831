/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import CompanyDBLayout from './layouts/CompanyDashboard';
import MedicalAuditorLayout from './layouts/MedicalAuditor';
import Employee from 'layouts/Employee';
import HRPortal from 'layouts/HRPortal';
import RequireAuth from './RequireAuth';
import Policy from 'layouts/Policy';

const HRRoutes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/auth/login" />
  },
  {
    path: '/Privacy-Policy',
    component: Policy,
    routes: [
      {
        path: '/Privacy-Policy',
        exact: true,
        component: lazy(() => import('views/PrivacyPolicy'))
      }
    ]
  },
  {
    path: '/terms-conditions',
    component: Policy,
    routes: [
      {
        path: '/terms-conditions',
        exact: true,
        component: lazy(() => import('views/TermsConditions'))
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/HRLogin'))
      },
      {
        path: '/auth/forgetPassword',
        exact: true,
        component: lazy(() => import('views/ForgetPasswordHr'))
      },
      {
        path: '/auth/resetPassword/:token',
        exact: true,
        component: lazy(() => import('views/ResetPasswordHr'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },

  {
    path: '/hr-portal',
    component: HRPortal,
    routes: [
      {
        path: '/hr-portal',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/HRUserFiles')))
      },
      {
        path: '/hr-portal/user-files',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/HRUserFiles')))
      },
      {
        path: '/hr-portal/user-files/verify/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserFilesVerify'))
        )
      },
      {
        path: '/hr-portal/user-files/user-create-tag',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserCreateTag'))
        )
      },
      {
        path: '/hr-portal/user-files/configuration-legajo',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserConfigurationLegajo'))
        )
      },
      {
        path: '/hr-portal/user-files/user-files-view/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserFilesDetails'))
        )
      },
      {
        path: '/hr-portal/user-files/user-files-edit/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserFilesEdit'))
        )
      },
      {
        path: '/hr-portal/user-files/configuration-legajo/configuration-solaps',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserConfigurationSolapas'))
        )
      },
      {
        path: '/hr-portal/user-files/tag',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserFilesTag'))
        )
      },
      {
        path: '/hr-portal/receipts',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserReceipts'))
        )
      },
      {
        path: '/hr-portal/receipts/sendReceips',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/SendReceips')))
      },
      {
        path: '/hr-portal/receipts/sendReceips/:type',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/SendReceipsSuccess')))
      },
      // {
      //   path: '/hr-portal/receipts/sendReceips/cancel',
      //   exact: true,
      //   component: RequireAuth(lazy(() => import('views/HRPortal/SendReceipsSuccess')))
      // },
      {
        path: '/hr-portal/receipts/receiptsList/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserReceiptsList'))
        )
      },
      {
        path: '/hr-portal/receipts/view/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/ReceiptsView'))
        )
      },
      {
        path: '/hr-portal/license',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRLicenseManagementList'))
        )
      },
      {
        path: '/hr-portal/license/auditoríaView/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/AuditoríaLicenseView'))
        )
      },
      {
        path: '/hr-portal/license/noAprobadaView/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/NoAprobadaLicenseView'))
        )
      },
      {
        path: '/hr-portal/license/aprobadaView/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/AprobadaLicenseView'))
        )
      },
      {
        path: '/hr-portal/license/pendienteView/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/PendienteView'))
        )
      },
      {
        path: '/hr-portal/license/Edit/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/LicenseEdit')))
      },
      {
        path: '/hr-portal/signings',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/Signings')))
      },
      {
        path: '/hr-portal/communication',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/HRCommList')))
      },
      {
        path: '/hr-portal/communication/communications/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/HRCommLists')))
      },
      {
        path: '/hr-portal/communication/communications/view/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRCommunicationView'))
        )
      },
      {
        path: '/hr-portal/communication/communications/view',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/HRCommView')))
      },
      {
        path: '/hr-portal/communication/create',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRCommCreate'))
        )
      }
    ]
  },

  {
    route: '*',
    component: CompanyDBLayout,
    routes: []
  }
];

export default HRRoutes;

/* eslint-disable react/display-name */
import React, { useState, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { ListItem, Button, Collapse, colors, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,

  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    // color: colors.blueGrey[800],
    color: '#0B74D1',
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium
    },
  },
  icon: {
    color: theme.palette.icon,
    width: 56
    // display: 'flex',
    // alignItems: 'center',
    // marginRight: theme.spacing(1)
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    flexDirection: 'row'
  },
  active: {
    // color: '#0B74D1',
    // color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    },
    borderRadius: '0px 30px 30px 0px',
    backgroundColor: '#0B74D1',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#0B74D1'
    }
  },
  activetext:{
    color: '#0B74D1',
  },
  disableOpacity:{
    opacity:0.2,
    color: theme.palette.icon,
    width: 56
  }
}));

const NavigationListItem = props => {
  const {
    title,
    href,
    depth,
    children,
    // icon: Icon,
    icon,
    className,
    open: openProp,
    label: Label,
    activeIcon,
    disable,
    ...rest
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(open => !open);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = {
    paddingLeft
  };



  // console.log("openProp", openProp);
  // console.log("activeIcon", activeIcon);
  // console.log("children", children);
  console.log("disabledisabledisable", disable,title);

  const handleBack =() => {
    localStorage.removeItem("PageValue")
    localStorage.removeItem("linkFileSigned")
    localStorage.removeItem("filepath")
    localStorage.removeItem("idOperation")
  }
  
  if (children) {
    return (
      <ListItem
        {...rest}
        className={clsx(classes.item, className)}
        disableGutters
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
          style={style}
        >
          {/* {Icon && <Icon className={classes.icon} />} */}
          {/* <img src={icon} className={classes.icon} /> */}
          {/* {title} */}
          <Typography className={classes.activetext}>{title}</Typography>

        </Button>
        {/* <Collapse in={open}>{children}</Collapse> */}
      </ListItem>
    );
  } else {
    return (
      <ListItem
        {...rest}
        className={clsx(classes.itemLeaf, className)}
        disableGutters
      >
        <Button
          activeClassName={classes.active}
          // className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          classes={{
            root: classes.buttonLeaf, // class name, e.g. `classes-nesting-root-x`
            label: classes.label, // class name, e.g. `classes-nesting-label-x`
          }}
          component={CustomRouterLink}
          // exact
          style={style}
          to={href}
          disabled={disable}
          onClick={()=>handleBack()}
        >
          {/* {Icon && <Icon className={classes.icon} />} */}

          {/* <img src={openProp ? activeIcon : icon} className={(disable) ? classes.disableOpacity : classes.icon}  /> */}
          {title}
          {/* <Typography className={classes.activetext}>{title}</Typography> */}
          {/* {Label && (
            <span className={classes.label}>
              <Label />
            </span>
          )} */}
        </Button>
      </ListItem>
    );
  }
};

NavigationListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavigationListItem.defaultProps = {
  depth: 0,
  open: false
};

export default NavigationListItem;

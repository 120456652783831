/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import CompanyDBLayout from './layouts/CompanyDashboard';
import MedicalAuditorLayout from './layouts/MedicalAuditor';
import Employee from 'layouts/Employee';
import HRPortal from 'layouts/HRPortal';
import RequireAuth from './RequireAuth';
import Policy from 'layouts/Policy';

const CompanyRoutes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/auth/login" />
  },
  {
    path: '/Privacy-Policy',
    component: Policy,
    routes: [
      {
        path: '/Privacy-Policy',
        exact: true,
        component: lazy(() => import('views/PrivacyPolicy'))
      }
    ]
  },
  {
    path: '/terms-conditions',
    component: Policy,
    routes: [
      {
        path: '/terms-conditions',
        exact: true,
        component: lazy(() => import('views/TermsConditions'))
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/CompanyLogin'))
      },
      {
        path: '/auth/forgetPassword',
        exact: true,
        component: lazy(() => import('views/ForgetPassword'))
      },
      {
        path: '/auth/resetPassword/:token',
        exact: true,
        component: lazy(() => import('views/ResetPassword'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/',
    component: CompanyDBLayout,
    routes: [
      {
        path: '/company',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/UserManagementList')))
      },
      {
        path: '/company/user',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/UserManagementList')))
      },
      {
        path: '/company/user/userAdd',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/UserAdd')))
      },
      {
        path: '/company/user/userView/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/UserView')))
      },
      {
        path: '/company/user/userEdit/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/UserEdit')))
      },
      {
        path: '/company/user/userupload',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/UserUpload')))
      },
      {
        path: '/company/license',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/LicenseManageList')))
      },
      {
        path: '/company/license/medicallicense/add',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/MedicalLicenseAdd')))
      },
      {
        path: '/company/license/medicallicense/view/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/MedicalLicenseView')))
      },
      {
        path: '/company/license/medicallicense/edit/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/MedicalLicenseEdit')))
      },
      {
        path: '/company/license/administlicense/add',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/AdministLicenseAdd')))
      },
      {
        path: '/company/license/administlicense/view/:id',  
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/AdministLicenseView')))
      },
      {
        path: '/company/license/administlicense/edit/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/AdministLicenseEdit')))
      },
      {
        path: '/company/license/upload',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/LicensesUpload')))
      },
      // {
      //   path: '/company/manageModify',
      //   exact: true,
      //   component: RequireAuth(lazy(() => import('views/Company/ManageModify')))
      // },
      {
        path: '/company/transfers',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/TransfersManagementList')))
      },
      {
        path: '/company/transfers/view/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/TransfersView')))
      },
      {
        path: '/company/transfers/edit/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/TransfersEdit')))
      },
      {
        path: '/company/transfers/add',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/TransfersAdd')))
      },
    ]
  },
  {
    route: '*',
    component: CompanyDBLayout,
    routes: [

    ]
  }
];

export default CompanyRoutes;
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

export const matchNothingRegex = /(?!)/;

// const regex = /^[+-]?\d+(\.\d+)?$/gi;
const regex = /[^a-z0-9]/gi;

const Dnitextfiled = ({  onChange, ...rest }) => {
    const handleChange = useCallback(
        (e) => {
            e.currentTarget.value = e.currentTarget.value.replace(regex, "");
            onChange(e);
        },
        [onChange, regex]
    );

    return <TextField
        variant="outlined"
        size="small"
        label="D.N.I. :"
        onChange={handleChange}
        required
        fullWidth
        name="DNI"
        {...rest}
    />;
};

export default React.memo(Dnitextfiled);

Dnitextfiled.propTypes = {
    onChange: PropTypes.func.isRequired,
    regex: PropTypes.instanceOf(RegExp)
};

Dnitextfiled.defaultProps = {
    regex: matchNothingRegex
};
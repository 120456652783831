import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

export const matchNothingRegex = /(?!)/;
const regex = /[^-()a-z0-9]/gi;

const Telefonotextfield = ({ onChange, ...rest }) => {
    const handleChange = useCallback(
        (e) => {
           
            e.currentTarget.value = e.currentTarget.value.replace(regex, "");
            onChange(e);
        },
        [onChange, regex]
    );

    return <TextField
        variant="outlined"
        size="small"
        // label="Teléfono :"
        onChange={handleChange}
        required
        fullWidth
        name="telephone"
        {...rest}
    />;
};

export default React.memo(Telefonotextfield);

Telefonotextfield.propTypes = {
    onChange: PropTypes.func.isRequired,
    regex: PropTypes.instanceOf(RegExp)
};

Telefonotextfield.defaultProps = {
    regex: matchNothingRegex
};
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import CompanyDBLayout from './layouts/CompanyDashboard';
import MedicalAuditorLayout from './layouts/MedicalAuditor';
import Employee from 'layouts/Employee';
import HRPortal from 'layouts/HRPortal';
import RequireAuth from './RequireAuth';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/auth/admin/login" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/admin/login',
        exact: true,
        component: lazy(() => import('views/AdminLogin'))
      },
      {
        path: '/auth/company/login',
        exact: true,
        component: lazy(() => import('views/CompanyLogin'))
      },
      {
        path: '/auth/employee/login',
        exact: true,
        component: lazy(() => import('views/EmployeeLogin'))
      },
      {
        path: '/auth/medical/login',
        exact: true,
        component: lazy(() => import('views/MedicalLogin'))
      },
      {
        path: '/auth/hr/login',
        exact: true,
        component: lazy(() => import('views/HRLogin'))
      },
      {
        path: '/auth/forgetPassword',
        exact: true,
        component: lazy(() => import('views/ForgetPassword'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/admin',
    component: DashboardLayout,
    routes: [
      {
        path: '/admin',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Admin/ClientManagementList'))
        )
      },
      {
        path: '/admin/client',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Admin/ClientManagementList'))
        )
      },
      {
        path: '/admin/client/add',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/CompanyAdd')))
      },
      {
        path: '/admin/client/view/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/CompanyView')))
      },
      {
        path: '/admin/client/edit/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/CompanyEdit')))
      },
      {
        path: '/admin/user',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Admin/UserManagementList'))
        )
      },
      {
        path: '/admin/user/edit/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/UserEdit')))
      },
      {
        path: '/admin/user/view/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/UserView')))
      },
      {
        path: '/admin/user/userUpload',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/UserUpload')))
      },
      {
        path: '/admin/user/userAdd',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/UserAdd')))
      },
      {
        path: '/admin/manageModify',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/ManageModify')))
      },
      {
        path: '/admin/manageModify/modifyClients/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/ModifyClients')))
      }
    ]
  },
  {
    path: '/company',
    component: CompanyDBLayout,
    routes: [
      {
        path: '/company',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Company/UserManagementList'))
        )
      },
      {
        path: '/company/user',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Company/UserManagementList'))
        )
      },
      {
        path: '/company/user/userAdd',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/UserAdd')))
      },
      {
        path: '/company/user/userView/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/UserView')))
      },
      {
        path: '/company/user/userEdit/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/UserEdit')))
      },
      {
        path: '/company/user/userupload',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/UserUpload')))
      },
      {
        path: '/company/license',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Company/LicenseManageList'))
        )
      },
      {
        path: '/company/license/medicallicense/add',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Company/MedicalLicenseAdd'))
        )
      },
      {
        path: '/company/license/medicallicense/view/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Company/MedicalLicenseView'))
        )
      },
      {
        path: '/company/license/medicallicense/edit/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Company/MedicalLicenseEdit'))
        )
      },
      {
        path: '/company/license/administlicense/add',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Company/AdministLicenseAdd'))
        )
      },
      {
        path: '/company/license/administlicense/view/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Company/AdministLicenseView'))
        )
      },
      {
        path: '/company/license/administlicense/edit/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Company/AdministLicenseEdit'))
        )
      },
      {
        path: '/company/license/upload',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Company/LicensesUpload'))
        )
      },
      // {
      //   path: '/company/manageModify',
      //   exact: true,
      //   component: RequireAuth(lazy(() => import('views/Company/ManageModify')))
      // },
      {
        path: '/company/transfers',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Company/TransfersManagementList'))
        )
      },
      {
        path: '/company/transfers/view/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Company/TransfersView'))
        )
      },
      {
        path: '/company/transfers/edit/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Company/TransfersEdit'))
        )
      },
      {
        path: '/company/transfers/add',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Company/TransfersAdd')))
      }
    ]
  },
  {
    path: '/medicalAuditor',
    component: MedicalAuditorLayout,
    routes: [
      {
        path: '/medicalauditor',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/MedicalAuditorPortal/LicenseManagementList'))
        )
      },
      {
        path: '/medicalauditor/license',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/MedicalAuditorPortal/LicenseManagementList'))
        )
      },
      {
        path: '/medicalauditor/license/view/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/MedicalAuditorPortal/LicenseView'))
        )
      },
      {
        path: '/medicalauditor/license/edit/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/MedicalAuditorPortal/LicenseEdit'))
        )
      },
      {
        path: '/medicalauditor/auditor',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/MedicalAuditorPortal/AuditorManagementList'))
        )
      },
      {
        path: '/medicalauditor/license/view/noaprobadaview/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/MedicalAuditorPortal/NoAprobadaLicenseView')))
      },
      {
        path: '/medicalauditor/auditor/edit/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/MedicalAuditorPortal/AuditorEdit'))
        )
      }
    ]
  },
  {
    path: '/hr-portal',
    component: HRPortal,
    routes: [
      {
        path: '/hr-portal',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/HRUserFiles')))
      },
      {
        path: '/hr-portal/user-files',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/HRUserFiles')))
      },
      {
        path: '/hr-portal/user-files/verify/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserFilesVerify'))
        )
      },
      {
        path: '/hr-portal/user-files/user-create-tag',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserCreateTag'))
        )
      },
      {
        path: '/hr-portal/user-files/configuration-legajo',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserConfigurationLegajo'))
        )
      },
      {
        path: '/hr-portal/user-files/user-files-view/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserFilesDetails'))
        )
      },
      {
        path: '/hr-portal/user-files/user-files-edit/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserFilesEdit'))
        )
      },
      {
        path: '/hr-portal/user-files/configuration-legajo/configuration-solaps',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserConfigurationSolapas'))
        )
      },
      {
        path: '/hr-portal/user-files/tag',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserFilesTag'))
        )
      },
      {
        path: '/hr-portal/receipts',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserReceipts'))
        )
      },
      {
        path: '/hr-portal/receipts/sendReceips',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/SendReceips')))
      },
      {
        path: '/hr-portal/receipts/sendReceips/:type',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/SendReceipsSuccess')))
      },
      // {
      //   path: '/hr-portal/receipts/sendReceips/cancel',
      //   exact: true,
      //   component: RequireAuth(lazy(() => import('views/HRPortal/SendReceipsSuccess')))
      // },
      {
        path: '/hr-portal/receipts/receiptsList/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRUserReceiptsList'))
        )
      },
      {
        path: '/hr-portal/receipts/view/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/ReceiptsView'))
        )
      },
      {
        path: '/hr-portal/license',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRLicenseManagementList'))
        )
      },
      {
        path: '/hr-portal/license/auditoríaView/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/AuditoríaLicenseView'))
        )
      },
      {
        path: '/hr-portal/license/noAprobadaView/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/NoAprobadaLicenseView'))
        )
      },
      {
        path: '/hr-portal/license/aprobadaView/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/AprobadaLicenseView'))
        )
      },
      {
        path: '/hr-portal/license/pendienteView/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/PendienteView'))
        )
      },
      {
        path: '/hr-portal/license/Edit/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/LicenseEdit')))
      },
      {
        path: '/hr-portal/signings',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/Signings')))
      },
      {
        path: '/hr-portal/communication',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/HRCommList')))
      },
      {
        path: '/hr-portal/communication/communications/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/HRCommLists')))
      },
      {
        path: '/hr-portal/communication/communications/view',
        exact: true,
        component: RequireAuth(lazy(() => import('views/HRPortal/HRCommView')))
      },
      {
        path: '/hr-portal/communication/create',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/HRPortal/HRCommCreate'))
        )
      }
    ]
  },
  {
    path: '/employee',
    component: Employee,
    routes: [
      {
        path: '/employee',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/MyProfile'))
        )
      },
      {
        path: '/employee/my-profileview/my-profile',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/MyProfileDetails'))
        )
      },
      {
        path: '/employee/my-profileview',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/MyProfile'))
        )
      },
      {
        path: '/employee/receipts',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/ReceiptsList'))
        )
      },
      {
        path: '/employee/signings',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/fichajesList'))
        )
      },
      {
        path: '/employee/communications',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/EmCommunicationList'))
        )
      },
      {
        path: '/employee/communications/view/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/EmCommunicationView'))
        )
      },
      {
        path: '/employee/receipts/view/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/ReceiptsView'))
        )
      },
      {
        path: '/employee/communications/detailsView/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/EmCommunicationDetailsView'))
        )
      },
      {
        path: '/employee/licences',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/EmLicenseManagementList'))
        )
      },
      {
        path: '/employee/licences/add',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/EmLicenseAdd'))
        )
      },
      {
        path: '/employee/licences/auditoríaView/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/AuditoríaLicenseView'))
        )
      },
      {
        path: '/employee/licences/noAprobadaView/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/NoAprobadaLicenseView'))
        )
      },
      {
        path: '/employee/licences/aprobadaView/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/AprobadaLicenseView'))
        )
      },
      {
        path: '/employee/licences/licenciassolicitud',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/EmLicenseRequestList'))
        )
      },
      {
        path: '/employee/licences/licenciassolicitud/pendienteView/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/LicenciasSolicitudPendienteView'))
        )
      }, 
      {
        path: '/employee/licences/licenciassolicitud/noAprobadaView/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/LicenciasSolicitudNoAprobadaLicenseView'))
        )
      },
      {
        path: '/employee/licences/licenciassolicitud/aprobadaView/:id',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/EmployeePortal/LicenciasSolicitudAprobadaLicenseView'))
        )
      },
      {
        path: '/employee/licences/licenciassolicitud/Edit/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/LicenciasSolicitudEdit')))
      },
      {
        path: '/employee/licences/view/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/AdministrativeLicences')))
      },
    ]
  },

  {
    route: '*',
    component: CompanyDBLayout,
    routes: []
  }
];

export default routes;

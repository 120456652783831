import React, { useCallback } from "react"
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import MaskedInput from "react-text-mask";

const regex = [/[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/gi];
function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
                // mask={[
                //     /[0-9]/,
                //     /[0-9]/,
                //     "-",
                //     /[0-9]/,
                //     /[0-9]/,
                //     /[0-9]/,
                //     /[0-9]/,
                //     /[0-9]/,
                //     /[0-9]/,
                //     /[0-9]/,
                //     /[0-9]/,
                //     "-",
                //     /[0-9]/
                // ]}
            placeholderChar={"\u2000"}
            showMask
        />
    );
}


TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired
};

const Cuiltextfield = ({ onChange, ...rest }) => {
    const handleChange = useCallback(
        (e) => {
            e.currentTarget.value = e.currentTarget.value.replace(regex, "");
            onChange(e);
        },
        [onChange, regex]
    );



    return <TextField
        variant="outlined"
        required
        fullWidth
        // name="CUIL"
        size="small"
        // label="C.U.I.L :"
        onChange={handleChange}
        // type="number"
        InputProps={{
            inputComponent: TextMaskCustom,
        }}
        {...rest}
       
    />;
};


Cuiltextfield.propTypes = {
    onChange: PropTypes.func.isRequired,
    regex: PropTypes.instanceOf(RegExp)
};

// Cuil.defaultProps = {
//     regex: matchNothingRegex
// };

export default React.memo(Cuiltextfield);

import React, { useCallback } from "react"

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Button, Input, TextField, InputBase, Typography, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MaskedInput from "react-text-mask";

const regex = [/[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/gi];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    // border: '1px solid #70707036',
    // borderRadius: '7px',

  },
  search: {
    flexGrow: 1,
    // height: 32,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',

  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon

  },
  searchInput: {
    // border: '1px solid #909090',
    flexGrow: 1,
    borderRadius: '7px',
    height: 32,
    '& > .MuiInputBase-root': {
      color: "black",
      fontWeight: "bolder",
      fontSize: "16px",
    },
  },
  prefixInput: {
    '& > .MuiTypography-root': {
      color: "black",
      fontSize: "small",
    },
  },
  searchButton: {
    marginLeft: theme.spacing(2)
  },
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        "-",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        "-",
        /[0-9]/
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}


TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
};

const Search = props => {
  const { onSearch, className, onChange, label, boldResult, title, ...rest } = props;

  const classes = useStyles();
  console.log(onChange)
  const handleChange = useCallback(
    (e) => {
      e.currentTarget.value = e.currentTarget.value.replace(regex, "");
      onChange(e);
    },
    [onChange, regex]
  );

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div
        className={classes.search}
        elevation={1}
      >
        <TextField
          className={classes.searchInput}
          disableUnderline
          variant="outlined"
          label={label ?? ''}
          size="small"
          onChange={handleChange}
          // type="number"
          // onChange={(e) => handelSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment className={classes.prefixInput} position="start">{title ?? ''}</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end"><SearchIcon className={classes.searchIcon} /> </InputAdornment>
            ),
            inputComponent: TextMaskCustom,
          }}
        />

      </div>



    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  regex: PropTypes.instanceOf(RegExp)
};

export default React.memo(Search);


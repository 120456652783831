/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';

export default [
  {
    title: 'Back',
    pages: [
      {
        title: 'Licencias',
        href: '/medicalAuditor/license',
        icon: '/images/logos/Licencias.svg',
        activeIcon: '/images/logos/license_white.svg'
      },
      {
        title: 'Auditorías',
        href: '/medicalAuditor/auditor',
        icon: '/images/logos/auditor.svg',
        activeIcon: '/images/logos/auditor_white.svg'
      },
    ]
  }
];

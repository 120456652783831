/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';

export default [
  {
    title: 'Back',
    pages: [
      {
        title: 'Mi Perfil',
        href: '/employee/my-profileview',
        icon: '/images/logos/person-circle-blue.svg',
        activeIcon: '/images/Usuarios.svg',
        disable:false
      },

      {
        title: 'Licencias',
        href: '/employee/licences',
        icon: '/images/logos/Licencias.svg',
        activeIcon: '/images/logos/license_white.svg',
        disable:false
      },
      {
        title: 'Recibos',
        href: '/employee/receipts',
        icon: '/images/logos/Recibos.svg',
        activeIcon: '/images/logos/RecibosWhite.svg',
        disable: false
      },
      {
        title: 'Fichajes',
        href: '/employee/signings',
        icon: '/images/logos/Transfers_blue.svg',
        activeIcon: '/images/logos/Transfers.svg',
        disable: false
      },
      {
        title: 'Comunicaciones',
        href: '/employee/communications',
        icon: '/images/logos/Comunicaciones.svg',
        activeIcon: '/images/logos/ComunicacionesWhite.svg',
        disable:false
      },
    ]
  }
];

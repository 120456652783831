import * as actionTypes from 'actions';

const initialState = {
  loggedIn: true,
  user: {
    first_name: 'Shen',
    last_name: 'Zhi',
    email: 'demo@gmail.io',
    avatar: '/images/avatars/avatar_11.png',
    bio: 'Brain Director',
    role: 'ADMIN' // ['GUEST', 'USER', 'ADMIN']
  },
  module: {
    license: false
  }
};

const sessionReducer = (state = initialState, action) => {
  console.log("actionactionactionaction",action);
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...initialState
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: {
          role: 'GUEST'
        }
      };
    }

    case actionTypes.SESSION_MODULE: {
      return {
        ...state,
        module: {
          license: action.license
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;

/* eslint-disable no-unused-vars */
import React from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from "react-router-dom";
import useRouter from 'utils/useRouter';


import {
  // AppBar,
  Box,
  IconButton,
  // Container,
  Toolbar,
  // Typography,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    height: '113px',
  },
  navback: {
    height: '40px',
    weight: '40px',
    // marginLeft: '26px',
    marginRight: theme.spacing(3),
  },
  navlogo: {
    height: '90px',
    zIndex: '5',
    marginLeft: theme.spacing(3),
  },
  ArrowBackSVg: {
    marginLeft: 56,
    // marginTop: 44,
  },
  navimg: {
    marginTop: 24,
  },
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;
  const classes = useStyles();
  let history = useHistory();
  const router = useRouter();

  return (

    <Box
      {...rest}
      className={clsx(classes.root, className)}

    >
      <Toolbar>
        <div className={classes.navback}>
          <IconButton aria-label="go-back" className={classes.ArrowBackSVg} onClick={() => history.goBack()}>
            <img src="/images/arrow-back.svg" width="40px" />
          </IconButton>
        </div>
        <RouterLink to="/admin/client" >
          <img src="/images/logos/logo.png" width={250} className={classes.navimg} />
        </RouterLink>

      </Toolbar>
    </Box>

  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;

import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Input,
  Paper,
  Tooltip,
  Grid,
  Typography,
  Box,
  Menu,
  MenuItem,
  ListItemText,
  Snackbar
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import SendIcon from '@material-ui/icons/Send';
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useEffect } from 'react';
import Api from 'views/Helpers/ApiHandler';
import MuiAlert from '@material-ui/lab/Alert';
import Cookies from 'js-cookie';
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  paper: {
    flexGrow: 1,
    padding: theme.spacing(0.5, 2)
  },
  input: {
    width: '100%'
  },
  divider: {
    width: 1,
    height: 24
  },
  fileInput: {
    display: 'none'
  },
  perfilText: {
    letterSpacing: 9,
    padding: 5,
    textAlign: 'right',
    color: '#236084',
    fontWeight: 600
  },
  sectopTitle: {
    backgroundColor: '#0B74D1',
    width: 'fit-content',
    padding: '5px 16px 5px 16px',
    borderRadius: '0px 0px 5px 5px'
  },
  sectopTitleTypo: {
    letterSpacing: 9,
    color: '#FFFFFF'
  },
  headingLogo: {
    marginLeft: '10px'
  },
  topHeaderDiv: {},
  search: {
    flexGrow: 1,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon
  },
  searchInput: {
    flexGrow: 1
  },
  searchButton: {
    marginLeft: theme.spacing(2)
  },
  secondHeaderTableRow: {
    padding: '30px 70px',
    alignSelf: 'center'
  },
  secondHeaderTable: {
    marginTop: 12,
    padding: '0px ​20px 0px 20p'
  },
  tableRoot: {
    borderCollapse: 'initial'
  },
  resultCard: {
    // padding: 80,
    borderRadius: '40px 40px 0px 0px'
  }
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: 'transparent',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'black'
      }
    }
  }
}))(MenuItem);

const CardTopHeader = props => {
  const { className, title, ...rest } = props;

  const classes = useStyles();
  const fileInputRef = useRef(null);
  const [value, setValue] = useState('');
  const session = useSelector(state => state.session);

  const handleChange = event => {
    event.persist();

    setValue(event.target.value);
  };

  const handleAttach = () => {
    fileInputRef.current.click();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileList, setProfileList] = React.useState(null);
  const [sessionMessage, setSessionMessage] = useState({
    message: '',
    type: '',
    open: false
  });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDailogClose = (event, reason) => {
    setSessionMessage({ message: '', type: '', open: false });
  };

  useEffect(() => {
    if(title === "COLABORADOR" || title === "RECURSOS HUMANOS" || title === "AUDITOR MEDICO"){
      getProfileList();
    }
  }, []);

  const getProfileList = () => {
    api.get('getprofilelist').then(result => {
      if (title === "COLABORADOR") {
        const tmp = result.data 
        tmp.splice(0, 1)
        setProfileList(tmp);
      }else if(title === "RECURSOS HUMANOS"){
        const tmp = result.data 
        tmp.splice(1, 1)
        setProfileList(tmp);
      }else if(title === "AUDITOR MEDICO"){
        const tmp = result.data 
        tmp.splice(2, 1)
        setProfileList(tmp);
      }
    });
  };

  // const onClickRedirect = object => {
  //   if (object === 3) {
  //     const EMURL = process.env.REACT_APP_EMPLOYEE_URL
  //     // const EMURL = 'https://login.numi.com.ar'
  //     window.location.replace(`${EMURL}/employee/my-profileview`);
  //   } else if (object === 4) {  
  //     const HRURL = process.env.REACT_APP_HR_URL
  //     // const HRURL = "https://hr.numi.com.ar"
  //     window.location.replace(`${HRURL}/hr-portal/user-files`);
  //   } else if (object === 5) {
  //     const MDURL = process.env.REACT_APP_MEDICAL_URL
  //     // const MDURL = "https://sm.numi.com.ar"
  //     window.location.replace(`${MDURL}/medicalAuditor/license`);
  //   }
  // };
  const EMURL = process.env.REACT_APP_EMPLOYEE_URL
  // const EMURL = "http://hccqa-app.numi.com.ar.local:3000"
  const HRURL = process.env.REACT_APP_HR_URL
  // const HRURL = "http://hccqa-hr-app.numi.com.ar.local:3000"
  const MDURL = process.env.REACT_APP_MEDICAL_URL
  // const MDURL = "http://hccqa-me-app.numi.com.ar.local:3000"
  // console.log(EMURL,"EMURL");
  // console.log(HRURL,"EMURL");
  // console.log(MDURL,"EMURL");


  const CookiesNow = Cookies.get()
  const history = useHistory()

  
  let allpsw =  CookiesNow.___username + "/" + CookiesNow.___password
  // console.log(authToken,"authToken");
  let encoded = window.btoa(allpsw);
  // let decode = window.atob(encoded);
  // console.log(encoded,"encoded");
  // console.log(decode,"encoded");
  const handledek = (object) => {
    console.log(object,"object");

    if(object === 3){
      window.open(`http://${EMURL}/auth/login?token=${encoded}`, '_self');
    }else if(object === 4){
      window.open(`http://${HRURL}/auth/login?token=${encoded}`, '_self');
    }else if(object === 5){
      window.open(`http://${MDURL}/auth/login?token=${encoded}`, '_self');
    }
    // history.replace({
    //   pathname: `${EMURL}/auth/login`,
    //   search: '', 
    //   state: { propsData: CookiesNow }
    // });
  }

  // pathname: '/auth/details1',
  //     search: '', 
  //     state: { propsData: formState.values }

  return (
    <div className={classes.topHeaderDiv}>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleDailogClose}>
          <Alert onClose={handleDailogClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start">
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
            className={classes.perfilText}>
            PERFIL
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex" className={classes.sectopTitle}>
            <Box>
              <Typography
                color="textSecondary"
                variant="body2"
                className={classes.sectopTitleTypo}>
                {title ?? ''} &nbsp;
              </Typography>
            </Box>
            <Box>
              <img
                src="/images/dots@2x.png"
                width="10px"
                className={classes.headingLogo}
                onClick={
                  title === 'ADMINISTRADOR' || title === 'HCC ADMIN'
                    ? null
                    : handleClick
                }
                style={
                  title === 'ADMINISTRADOR' || title === 'HCC ADMIN'
                    ? { cursor: 'default' }
                    : { cursor: 'pointer' }
                }
              />
               {profileList?.length === 0 ? null : <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>  
                {title === 'ADMINISTRADOR' || title === 'HCC ADMIN'
                  ? null
                  : profileList?.length ?
                    profileList?.map((obj, index) => {
                      return (
                        <StyledMenuItem  key={`obj_${index}`}>
                          <ListItemText
                          onClick={() => {handledek(obj)}}
                            primary={
                              obj === 3
                                ? 'COLABORADOR'
                                : obj === 4
                                ? 'RECURSOS HUMANOS'
                                : obj === 5
                                ? 'AUDITOR MEDICO'
                                : ''
                            }
                          />
                        </StyledMenuItem>
                      );
                    }) : null} 
              </StyledMenu>}
            </Box>
          </Box>

          {/* <Grid
              container
              direction="row"
              // justifyContent="space-between"
              alignItems="flex-start"
            >

              <Grid item xs={11}>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  className={classes.sectopTitleTypo}
                >
                  {title ?? ''}
                </Typography>
              </Grid>
              <Grid item xs={1} >
                <img src="/images/dots@2x.png" width="10px" />
              </Grid>
            </Grid> */}
        </Grid>
      </Grid>

      
    </div>
  );
};

CardTopHeader.propTypes = {
  className: PropTypes.string
};

export default CardTopHeader;

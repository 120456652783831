import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${palette.divider}`,
    fontSize: 12,
    padding: 8,
    textAlign: 'center',
    maxWidth: 350,
  },
  head: {
    color: '#263238',
    fontSize: 14,
    borderBottom: 'none',
    padding: 10
  },
  stickyHeader:{
    backgroundColor: '#E8E8E8'
  }
};

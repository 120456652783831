import uuid from 'uuid/v1';
import moment from 'moment';

import mock from 'utils/mock';

mock.onGet('/api/management/auditorlicence').reply(200, {
  auditorlicence: [
    {
      legajo: "778482",
      nombre: 'Fernandez Mario Daniel',
      diagnóstico:'Faringitis aguda',
      Fecha_inicio: '77/84/8762',
      estado: 'Pendiente',
      horario: '8:30'
    },
    {
      legajo: "778482",
      nombre: 'Fernandez Mario Daniel',
      diagnóstico:'Gastroenterocolitis',
      Fecha_inicio: '77/84/8762',
      estado: 'Auditoría',
      horario: '8:45'
    },
    {
      legajo: "778482",
      nombre: 'Fernandez Mario Daniel',
      diagnóstico:'Esguince de tobillo',
      Fecha_inicio: '77/84/8762',
      estado: 'Auditoría',
      horario: '9:00'
    },
    {
      legajo: "778482",
      nombre: 'Fernandez Mario Daniel',
      diagnóstico:'Caso sospechoso Covid-19',
      Fecha_inicio: '77/84/8762',
      estado: 'Aprobada',
      horario: '9:15'
    },
    {
      legajo: "778482",
      nombre: 'Fernandez Mario Daniel',
      diagnóstico:'Esguince de tobillo',
      Fecha_inicio: '77/84/8762',
      estado: 'Aprobada',
      horario: '9:30'
    },
    {
      legajo: "778482",
      nombre: 'Fernandez Mario Daniel',
      diagnóstico:'Faringitis aguda',
      Fecha_inicio: '77/84/8762',
      estado: 'No Aprobada',
      horario: '9:45'
    },
   
  ]
});



import React, { useState, useEffect, useRef } from "react";
import {
    GoogleMap,
    LoadScript,
    Marker,
    InfoWindow,
    Autocomplete
} from "@react-google-maps/api";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/styles';
import Geocode from "react-geocode";
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

const useStyles = makeStyles((theme) => ({
    mapsty: {
        height: "100%",
        width: "100%"
    }
}))

const Map = ({ array, isAdding, getLocation, longitude, latitude, icons, drag, handelmapchange,handalDomicilio }) => {
    const [selected, setSelected] = useState({});
    const [valuemap, setvaluemap] = useState("");
    const [currentPosition, setCurrentPosition] = useState({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
    });

    const markerRef = useRef(null);

    const defaultCenter = {
        lat: 41.3851,
        lng: 2.1734
    };

    useEffect(() => {

    }, [currentPosition])

    const classes = useStyles();

    const onSelect = (item) => {
        setSelected(item);
    };

    const success = (position) => {
        const currentPosition = {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude)
        };
        setCurrentPosition(currentPosition);
    };

    const onMarkerDragEnd = (e) => {
        const lat = parseFloat(e.latLng.lat());
        // parseFloat(document.getElementById('lat').value)
        const lng = parseFloat(e.latLng.lng());
        setCurrentPosition({ lat, lng });
        handelmapchange(lat, lng)

        Geocode.fromLatLng(lat, lng).then(
            response => {
                const address = response.results[0].formatted_address;
                    // console.log(address);
                    handalDomicilio(address)
            },
            error => {
                console.error(error);
            }
        );
    };

    const footer = (
        <div className="footer">
            <div className="inner-footer">
                <span className="location-text">Choose location and press</span>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => getLocation(currentPosition)}
                >
                    Next
                </Button>
            </div>
        </div>
    );

    const mapStyles = () => {
        if (!isAdding) {
            return {
                // marginTop: "-20px",
                height: "100%",
                width: "100%",
                borderRadius: '10px',
            }
        }
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success);
    });


    return (
        <>
            <LoadScript
                id="script-loader"
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            >
                <GoogleMap
                    // mapContainerStyle={mapStyles}
                    mapContainerStyle={mapStyles()}
                    // className={classes.mapsty}
                    zoom={17}
                    center={currentPosition}
                >
                    {currentPosition.lat ? (
                        <Marker
                            position={currentPosition}
                            onDragEnd={(e) => onMarkerDragEnd(e)}
                            draggable={drag}
                            icon={icons}
                            // title={"abc"}
                            // label="abc"
                            opacity={1}
                        />
                    ) : null}
                   
                </GoogleMap>
            </LoadScript>
        </>
    );
};

export default Map;


{/* <GoogleMap
    id="example-map"
    mapContainerStyle={mapStyles()}
    draggable={true}
    zoom={13}
    center={currentPosition.lat ? currentPosition : defaultCenter}
>
    {array
        ? array.map((item) => {
            return (
                <Marker
                    key={item.id}
                    position={item.location}
                    onClick={() => onSelect(item)}
                />
            );
        })
        : null}
    {isAdding ? (
        <Marker
            style={greatPlaceStyle}
            position={currentPosition}
            ref={() => markerRef}
            onDragEnd={(e) => onMarkerDragEnd(e)}
            draggable={true}
        />
    ) : null}
    {selected.location ? (
        <InfoWindow
            position={selected.location}
            onCloseClick={() => setSelected({})}
        >
            <div className="infowindow">
                <p>{selected.title}</p>
                <img
                    src={selected.image}
                    className="small-image"
                    alt="rental"
                />
                <p>price: {selected.price}</p>
                <p>sqm2: {selected.sqm}</p>
                <p>bedrooms: {selected.bedrooms}</p>
            </div>
        </InfoWindow>
    ) : null}
</GoogleMap> */}
/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react';
import { matchPath } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, Typography } from '@material-ui/core';

import useRouter from 'utils/useRouter';
import { NavigationListItem } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  backForwadMenu: {
    alignSelf: 'end',
  },
  menulistul: {
    paddingTop: 10
  }
}));

const NavigationList = props => {
  const { pages, ...rest } = props;
  const classes = useStyles();
  const [license, setLicense] = React.useState(true);
  const [receipt, setReceipt] = React.useState(true);
  const [transfer, setTransfer] = React.useState(true);

  useEffect(() => {
    let license = JSON.parse(localStorage.getItem('license'));
    setLicense(license)
    let receipt = JSON.parse(localStorage.getItem('receipt'));
    setReceipt(receipt)
    let transfer = JSON.parse(localStorage.getItem('transfer'));
    setTransfer(transfer)
    console.log('license', license)
    console.log('receipt', receipt)
    console.log('transfer', transfer)
  }, [])

  return (
    <List className={classes.menulistul}>
      {pages.reduce(
        (items, page) => reduceChildRoutes({ items, page, ...rest }),
        []
      )}
    </List>
  );
};

NavigationList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array
};

const reduceChildRoutes = props => {
  const { router, items, page, depth } = props;

  console.log("pagepagepagepage",page)

  if (page.children) {

    const open = matchPath(router.location.pathname, {
      path: page.href,
      exact: false
    });

    items.push(
      <NavigationListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        label={page.label}
        open={Boolean(open)}
        title={page.title}
        activeIcon={page.activeIcon}
        disable={page.disable}
      >
        <NavigationList
          depth={depth + 1}
          pages={page.children}
          router={router}
        />
      </NavigationListItem>
    );
  } else {
    const open = matchPath(router.location.pathname, {
      path: page.href,
      exact: false
    });

    console.log("Updated flag", open)

    items.push(
      <NavigationListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        label={page.label}
        open={Boolean(open)}
        title={page.title}
        activeIcon={page.activeIcon}
        disable={page.disable}
      />
    );
  }

  return items;
};

const Navigation = props => {
  const { title, pages, className, component: Component, CollapseMenu, CollapseMenuStatus, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();

  return (
    <Component
      {...rest}
      className={clsx(classes.root, className)}
    >

      {/* {title && <Typography variant="overline" >{title}</Typography>} */}
      {/* <img src="/images/logos/back.svg" width={16} className={classes.backForwadMenu} />  */}

      {/* <img src="/images/logos/forword.svg" width={16} className={classes.backForwadMenu} onClick={CollapseMenu}/> */}

      <NavigationList
        depth={0}
        pages={pages}
        router={router}
      />
    </Component>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string
};

Navigation.defaultProps = {
  component: 'nav'
};

export default Navigation;

export default {
    root: {
        // minWidth:164
    },
    ul: {
        border: '1px solid #DEDEDE',
        borderRadius: 7,
        '& li':{
            // padding:4
        },
        
        '& li:first-child': {
            borderRight: '1px solid #DEDEDE',
            '& button::after': {
                content: '"PRE."',
                display: 'block'
            }
        },
        '& li:last-child': {
            borderLeft: '1px solid #DEDEDE',
            '& button::after': {
                content: '"SIG."',
            }
        },
    }
};

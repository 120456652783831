import { colors } from '@material-ui/core';

export default {
  root: {
    backgroundColor: "#E8E8E8",
    '& th:first-child': {
      borderRadius: '10px 0 0 10px'
    },
    '& th:last-child': {
      borderRadius: '0 10px 10px 0'
    },
    color: "#212121",

  },

};

/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';

export default [
  {
    title: 'Back',
    pages: [
      {
        title: 'Legajos',
        href: '/hr-portal/user-files',
        icon: '/images/logos/Legajos.svg',
        activeIcon: '/images/logos/LegajosWhite.svg',
        disable: false
      },
      {
        title: 'Recibos',
        href: '/hr-portal/receipts',
        icon: '/images/logos/Recibos.svg',
        activeIcon: '/images/logos/RecibosWhite.svg',
        disable: (typeof localStorage.getItem('Receipt') !== 'undefined' && localStorage.getItem('Receipt') !== null) ? ((localStorage.getItem('Receipt') == "true") ? false : true) : false
      },
      {
        title: 'Licencias',
        href: '/hr-portal/license',
        icon: '/images/logos/Licencias.svg',
        activeIcon: '/images/logos/license_white.svg',
        disable: (typeof localStorage.getItem('Licencias') !== 'undefined' && localStorage.getItem('Licencias') !== null) ? ((localStorage.getItem('Licencias') == "true") ? false : true) : false
      },

      {
        title: 'Fichajes',
        href: '/hr-portal/signings',
        icon: '/images/logos/Transfers_blue.svg',
        activeIcon: '/images/logos/Transfers.svg',
        disable: (typeof localStorage.getItem('Fichajes') !== 'undefined' && localStorage.getItem('Fichajes') !== null) ? ((localStorage.getItem('Fichajes') == "true") ? false : true) : false
      },
      {
        title: 'Comunicaciones',
        href: '/hr-portal/communication',
        icon: '/images/logos/Comunicaciones.svg',
        activeIcon: '/images/logos/ComunicacionesWhite.svg',
        disable: false
      },
      {
        title: 'Tareas',
        href: '/hr-portal/tareas',
        icon: '/images/logos/Tareas.svg',
        activeIcon: '/images/logos/TareasWhite.svg',
        disable: true
      },
    ]
  }
];

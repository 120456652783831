/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import CompanyDBLayout from './layouts/CompanyDashboard';
import MedicalAuditorLayout from './layouts/MedicalAuditor';
import Employee from 'layouts/Employee';
import HRPortal from 'layouts/HRPortal';
import RequireAuth from './RequireAuth';
import Policy from 'layouts/Policy';

const MedicalRoutes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/auth/login" />
  },
  {
    path: '/Privacy-Policy',
    component: Policy,
    routes: [
      {
        path: '/Privacy-Policy',
        exact: true,
        component: lazy(() => import('views/PrivacyPolicy'))
      }
    ]
  },
  {
    path: '/terms-conditions',
    component: Policy,
    routes: [
      {
        path: '/terms-conditions',
        exact: true,
        component: lazy(() => import('views/TermsConditions'))
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/MedicalLogin'))
      },
      {
        path: '/auth/forgetPassword',
        exact: true,
        component: lazy(() => import('views/ForgetPasswordMedical'))
      },
      {
        path: '/auth/resetPassword/:token',
        exact: true,
        component: lazy(() => import('views/ResetPasswordMedical'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/medicalAuditor',
    component: MedicalAuditorLayout,
    routes: [
      {
        path: '/medicalauditor',
        exact: true,
        component: RequireAuth(lazy(() => import('views/MedicalAuditorPortal/LicenseManagementList')))
      },
      {
        path: '/medicalauditor/license',
        exact: true,
        component: RequireAuth(lazy(() => import('views/MedicalAuditorPortal/LicenseManagementList')))
      },
      {
        path: '/medicalauditor/license/view/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/MedicalAuditorPortal/LicenseView')))
      },
      {
        path: '/medicalauditor/license/view/aprobadaview/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/MedicalAuditorPortal/AprobadaLicenseView')))
      },
      // {
      //   path: '/medicalauditor/license/view/auditoriaview/:id',
      //   exact: true,
      //   component: RequireAuth(lazy(() => import('views/MedicalAuditorPortal/AprobadaLicenseView')))
      // },
      {
        path: '/medicalauditor/license/view/noaprobadaview/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/MedicalAuditorPortal/NoAprobadaLicenseView')))
      },
      {
        path: '/medicalauditor/license/edit/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/MedicalAuditorPortal/LicenseEdit')))
      },
      {
        path: '/medicalauditor/auditor',
        exact: true,
        component: RequireAuth(lazy(() => import('views/MedicalAuditorPortal/AuditorManagementList')))
      },
      {
        path: '/medicalauditor/auditor/edit/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/MedicalAuditorPortal/AuditorEdit')))
      },
    ]
  },
  {
    route: '*',
    component: CompanyDBLayout,
    routes: [

    ]
  }
];

export default MedicalRoutes;




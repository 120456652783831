/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';


import { Label } from 'components';

export default [
  {
    title: 'Back',
    pages: [
      {
        title: 'Clientes',
        href: '/admin/client',
        icon: '/images/Group 8851.svg',
        activeIcon: '/images/Group 883.svg'
      },
      {
        title: 'Módulos',
        href: '/admin/manageModify',
        icon: '/images/Group 884.svg',
        activeIcon: '/images/Group 886.svg'
      },
      {
        title: 'Usuarios',
        href: '/admin/user',
        icon: '/images/person-circle-outline.svg',
        activeIcon: '/images/person-circle-outline-w.svg'
      },
    ]
  }
];

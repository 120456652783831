/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { Label } from 'components';


export default [
  {
    title: 'Back',
    pages: [
      {
        title: 'Usuarios',
        href: '/company/user',
        icon: '/images/logos/person-circle-blue.svg',
        activeIcon: '/images/Usuarios.svg',
        disable:false
        
      },
      {
        title: 'Licencias',
        href: '/company/license',
        icon: '/images/logos/Licencias.svg',
        activeIcon: '/images/logos/license_white.svg',
        disable:(typeof localStorage.getItem('Licencias') !== 'undefined' && localStorage.getItem('Licencias') !== null) ? ((localStorage.getItem('Licencias') == "true") ? false : true) : false
      },
      {
        title: 'Fichajes',
        href: '/company/transfers',
        icon: '/images/logos/Transfers_blue.svg',
        activeIcon: '/images/logos/Transfers.svg',
        disable: (typeof localStorage.getItem('Fichajes') !== 'undefined' && localStorage.getItem('Fichajes') !== null) ? ((localStorage.getItem('Fichajes') == "true") ? false : true) : false
      },
    ]
  }
];

/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import CompanyDBLayout from './layouts/CompanyDashboard';
import MedicalAuditorLayout from './layouts/MedicalAuditor';
import Employee from 'layouts/Employee';
import HRPortal from 'layouts/HRPortal';
import RequireAuth from './RequireAuth';
import Policy from 'layouts/Policy';

const EmployeeRoutes = [

  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/auth/login" />
  },
  {
    path: '/Privacy-Policy',
    component: Policy,
    routes: [
      {
        path: '/Privacy-Policy',
        exact: true,
        component: lazy(() => import('views/PrivacyPolicy'))
      }
    ]
  },
  {
    path: '/terms-conditions',
    component: Policy,
    routes: [
      {
        path: '/terms-conditions',
        exact: true,
        component: lazy(() => import('views/TermsConditions'))
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/EmployeeLogin'))
      },
      {
        path: '/auth/forgetPassword',
        exact: true,
        component: lazy(() => import('views/ForgetPasswordUser'))
      },
      {
        path: '/auth/resetPassword/:token',
        exact: true,
        component: lazy(() => import('views/ResetPasswordUser'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/employee',
    component: Employee,
    routes: [
      {
        path: '/employee',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/MyProfile')))
      },
      {
        path: '/employee/my-profileview/my-profile',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/MyProfileDetails')))
      },
      {
        path: '/employee/my-profileview',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/MyProfile')))
      },
      {
        path: '/employee/receipts',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/ReceiptsList')))
      },
      {
        path: '/employee/signings',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/fichajesList')))
      },
      {
        path: '/employee/communications',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/EmCommunicationList')))
      },
      {
        path: '/employee/communications/view/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/EmCommunicationView')))
      },
      {
        path: '/employee/receipts/view/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/ReceiptsView')))
      },
      {
        path: '/employee/communications/detailsView/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/EmCommunicationDetailsView')))
      },
      {
        path: '/employee/licences',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/EmLicenseManagementList')))
      },
      {
        path: '/employee/licences/add',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/EmLicenseAdd')))
      },
      {
        path: '/employee/licences/auditoríaView/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/AuditoríaLicenseView')))
      },
      {
        path: '/employee/licences/noAprobadaView/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/NoAprobadaLicenseView')))
      },
      {
        path: '/employee/licences/aprobadaView/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/AprobadaLicenseView')))
      },
      {
        path: '/employee/licences/view/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/AdministrativeLicences')))
      },
      {
        path: '/employee/licences/licenciassolicitud',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/EmLicenseRequestList')))
      },
      {
        path: '/employee/licences/licenciassolicitud/pendienteView/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/LicenciasSolicitudPendienteView')))
      },
      {
        path: '/employee/licences/licenciassolicitud/noAprobadaView/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/LicenciasSolicitudNoAprobadaLicenseView')))
      },
      {
        path: '/employee/licences/licenciassolicitud/aprobadaView/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/LicenciasSolicitudAprobadaLicenseView')))
      },
      {
        path: '/employee/licenciassolicitud/Edit/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/EmployeePortal/LicenciasSolicitudEdit')))
      },
      
    ]
  },

  {
    route: '*',
    component: CompanyDBLayout,
    routes: [

    ]
  }
];

export default EmployeeRoutes;
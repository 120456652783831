import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { NavBar, TopBar, ChatBar } from './components';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    background: 'url(/images/auth.png) no-repeat center center fixed',
    backgroundSize: 'cover'
  },
  topBar: {
    zIndex: 2,
    position: 'relative'
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBaropen: {
    marginTop: 24,
    height: "calc(100vh - 170px)",
    // overflowY: 'scroll'
    borderRadius: '0px 40px 40px 0px',
    zIndex: 3,
    width: 144,
    minWidth: 144,
    flex: '0 0 auto'
  },
  navBaropen1: {
    // marginTop: 100,
    // maxHeight: 800,
    // borderRadius: '0px 40px 40px 0px',
    // zIndex: 3,
    width: 144,
    minWidth: 144,
    // flex: '0 0 auto'
  },
  navBarclose: {
    marginTop: 23,
    height: "calc(100vh - 170px)",
    borderRadius: '0px 40px 40px 0px',
    zIndex: 3,
    width: 60,
    minWidth: 60,
    // width: 144,
    // minWidth: 144,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',

  }
}));

const MedicalAuditor = props => {
  const { route } = props;

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [togglemenu, settogglemenu] = useState(true)


  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  const toggleClick = () => {
    settogglemenu(!togglemenu)
  };

  return (
    <div className={classes.root}>
      {/* <PerfectScrollbar> */}
        <Suspense fallback={<LinearProgress />}>
          <TopBar
            className={classes.topBar}
            onOpenNavBarMobile={handleNavBarMobileOpen}
          />

          <div className={classes.container}>

            {togglemenu ?
              <NavBar
                className={classes.navBaropen}
                onMobileClose={handleNavBarMobileClose}
                openMobile={openNavBarMobile}
                toggleClick={toggleClick}
                togglemenu={togglemenu}
              /> :
              <div className={classes.navBaropen1}>
                <NavBar
                  className={classes.navBarclose}
                  onMobileClose={handleNavBarMobileClose}
                  openMobile={openNavBarMobile}
                  toggleClick={toggleClick}
                  togglemenu={togglemenu}
                />
              </div>
            }

            <main className={classes.content} >
              {renderRoutes(route.routes)}
            </main>
          </div>
          {/* <ChatBar /> */}
        </Suspense>
      {/* </PerfectScrollbar> */}
    </div >
  );
};

MedicalAuditor.propTypes = {
  route: PropTypes.object
};

export default MedicalAuditor;

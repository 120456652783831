/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import CompanyDBLayout from './layouts/CompanyDashboard';
import MedicalAuditorLayout from './layouts/MedicalAuditor';
import Employee from 'layouts/Employee';
import HRPortal from 'layouts/HRPortal';
import RequireAuth from './RequireAuth';
import Policy from 'layouts/Policy';

const AdminRoutes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/auth/login" />
  },
  {
    path: '/Privacy-Policy',
    component: Policy,
    routes: [
      {
        path: '/Privacy-Policy',
        exact: true,
        component: lazy(() => import('views/PrivacyPolicy'))
      }
    ]
  },
  {
    path: '/terms-conditions',
    component: Policy,
    routes: [
      {
        path: '/terms-conditions',
        exact: true,
        component: lazy(() => import('views/TermsConditions'))
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/AdminLogin'))
      },
      {
        path: '/auth/forgetPassword',
        exact: true,
        component: lazy(() => import('views/ForgetPassword'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/',
    component: DashboardLayout,
    routes: [
      {
        path: '/admin',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Admin/ClientManagementList'))
        )
      },
      {
        path: '/admin/client',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Admin/ClientManagementList'))
        )
      },
      {
        path: '/admin/client/add',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/CompanyAdd')))
      },
      {
        path: '/admin/client/view/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/CompanyView')))
      },
      {
        path: '/admin/client/edit/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/CompanyEdit')))
      },
      {
        path: '/admin/user',
        exact: true,
        component: RequireAuth(
          lazy(() => import('views/Admin/UserManagementList'))
        )
      },
      {
        path: '/admin/user/edit/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/UserEdit')))
      },
      {
        path: '/admin/user/view/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/UserView')))
      },
      {
        path: '/admin/user/userUpload',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/UserUpload')))
      },
      {
        path: '/admin/user/userAdd',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/UserAdd')))
      },
      {
        path: '/admin/manageModify',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/ManageModify')))
      },
      {
        path: '/admin/manageModify/modifyClients/:id',
        exact: true,
        component: RequireAuth(lazy(() => import('views/Admin/ModifyClients')))
      }
    ]
  },

  {
    route: '*',
    component: CompanyDBLayout,
    routes: []
  }
];

export default AdminRoutes;

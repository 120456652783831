import React, { Fragment, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Divider, Paper, Avatar, Typography, Button, IconButton, Snackbar } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';
import Collapse from '@material-ui/core/Collapse';
import InputIcon from '@material-ui/icons/Input';
import { useHistory } from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar';
import DialogDefault from '../../../../components/Dialog/DialogDefault'
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
// import { useSelector, useDispatch } from 'react-redux'



const useStyles = makeStyles(theme => ({
  root: {
    // height: '100%',
    // overflowY: 'auto'
  },
  content: {
    // padding: theme.spacing(2)
    padding: '40px 20px 16px 0px',
    display: 'flex',
    flexDirection: 'column',
  },
  content1: {
    // padding: theme.spacing(2)
    padding: '40px 20px 16px 0px',
    display: 'flex',
    flexDirection: 'column',
    width: 60,
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: "0px",
    height: 620,
    boxShadow: "inset 0 -40px 10px -10px #fff",
    '& .MuiList-padding': {
      paddingTop: "20px",
      // width: "fit-content",
      overflowY: "auto",
      // height: "550px",
    },
    '*::-webkit-scrollbar-thumb': {
      display: "none",
    }
  },
  CollapseMenuC: {
    // padding: '44px 0px 0px 0px',
    textAlign: 'center'
  },
  ArrowBackSVg: {
    marginLeft: 60
  },
  logoutButton: {
    top: 5,
    fontSize: 16,
    color: '#0B74D1',
    // marginTop: theme.spacing(2),
    // color:'#FFFFFF',
    fontWeight: 300,
    textTransform: "inherit",
    justifyContent: "center"
  },
  logoutIcon: {
    fontWeight: 300,
    // marginRight: theme.spacing(1)
    // marginTop:theme.spacing(1)
  },
  backForwadMenu: {
    float: 'right',
    alignSelf: 'end',
    cursor: 'pointer',
    // display: 'block'
  },
  togglenav: {
    // display:'none',
    visibility: 'hidden',

  },
}));

const NavBar = props => {

  const { togglemenu, toggleClick, openMobile, onMobileClose, className, ...rest } = props;
  let history = useHistory();
  const classes = useStyles();
  const router = useRouter();

  const session_module = useSelector(state => state.session.module);


  // console.log("sessionsessionsessionsessionsession",session_module)
  const [checked, setChecked] = React.useState(true);
  const [license, setLicense] = React.useState(true);
  const [receipt, setReceipt] = React.useState(true);
  const [transfer, setTransfer] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [sessionMessage, setSessionMessage] = React.useState({ message: '', type: '', open: false });

  const handleClose = (event, reason) => {
    setSessionMessage({ message: '', type: '', open: false });
  };


  useEffect(() => {
    let license = JSON.parse(localStorage.getItem('license'));
    setLicense(license)
    let receipt = JSON.parse(localStorage.getItem('receipt'));
    setReceipt(receipt)
    let transfer = JSON.parse(localStorage.getItem('transfer'));
    setTransfer(transfer)
    console.log('license', license)
    console.log('receipt', receipt)
    console.log('transfer', transfer)
  }, [])


  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  const handleCollapseMenuChange = () => {
    setChecked((prev) => !prev);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    // event.preventDefault();
    setOpen(true);
  };

  const handleDialogSubmit = async (event) => {
    router.history.push('/auth/login');
    localStorage.removeItem('authToken');
    localStorage.removeItem('authRole');
    localStorage.removeItem('authName');
    localStorage.removeItem('sessionMessage');
    localStorage.removeItem('Licencias');
    localStorage.removeItem('Fichajes');
    localStorage.removeItem('Receipt');
    localStorage.removeItem('connect');
    localStorage.removeItem('task');
  }





  const navbarContent = (
    <div className={togglemenu ? classes.content : classes.content1}>

      {togglemenu ? <img src="/images/logos/back.svg" onClick={toggleClick} width={16} className={classes.backForwadMenu} /> : <img src="/images/logos/forword.svg" onClick={toggleClick} width={16} className={classes.backForwadMenu} />}
      {togglemenu ? <div style={{ height: 'calc(100vh - 256px)', display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
        {/* <PerfectScrollbar> */}

          <nav className={classes.navigation}>
            {navigationConfig.map(list => (
              // console.log('list.pages[1]', list.pages[1]) 

              <Navigation
                component="div"
                key={list.title}
                pages={list.pages}
                title={list.title}
                CollapseMenu={handleCollapseMenuChange}
                CollapseMenuStatus={checked} />

            ))}

          </nav>
        {/* </PerfectScrollbar> */}
        <Button className={classes.logoutButton} color="inherit" onClick={(event) => handleSubmit(event)} >
          {/* <Button className={classes.logoutButton} color="inherit" component={RouterLink} to="/auth/login"> */}
          <img src="/images/log-out-outline.svg" className={classes.logoutIcon} width={50} />
          Salir
        </Button>
      </div> : <div className={classes.togglenav}>

      </div>}
      {open && <DialogDefault status={open} handleClose={handleCloseDialog} handleDialogClickSubmit={handleDialogSubmit} dailog={"Setlogout"} />}


    </div >
  );

  return (
    <Fragment>
      {sessionMessage && (
        <Snackbar open={sessionMessage.open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div
            {...rest}
            className={clsx(classes.root)}
          >
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Collapse in={checked} collapsedSize={50} className={classes.CollapseMenuC}>
          {/* <IconButton className={classes.ArrowBackSVg} aria-label="go-back" onClick={() => history.goBack()}>
            <img src="/images/arrow-back.svg" width="40px" />
          </IconButton> */}
          {/* <br /> */}
          {checked ?
            <Paper
              {...rest}
              className={clsx(classes.root, className)}
              elevation={1}
              square
            >
              <PerfectScrollbar>
                {navbarContent}
              </PerfectScrollbar>
            </Paper> : <Paper
              {...rest}
              className={clsx(classes.root, className)}
              elevation={1}
              square
            >
              {navbarContent}
            </Paper>}
        </Collapse>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
